/* eslint-disable react-func/max-lines-per-function */
/* eslint-disable max-lines-per-function */
import App from '../components/App'
import Cookies from 'js-cookie'
import Header from '../components/Header'
import React, { useEffect } from 'react'
import SEO from '../atoms/SEO'
import styled from 'styled-components'
import { FORM_ERROR } from 'final-form'
import { Grid } from '../atoms/Grid'
import { RegistrationSource } from '../atoms/mappers/gqlEnums/registrationSourse'
import {
  SEND_REGISTRATION_REQUEST,
  sendAdvCakePostback,
  sendCityAdsPostback,
  sendFormDataCarrotQuest,
  sendPostBack,
  sendPostBackAdmitad
} from '../api'
import { SelectPsychologistForm } from '../organisms/SelectPsychologistForm'
import { SendCodeModalWithContext } from '../organisms/RegistrationModals/CodeSendModal'
import { formatCatalogData } from '../atoms/mappers/wizard/utils'
import { getUTM } from '../atoms/initialQueryParams'
import { omit } from 'rambda'
import { pressSendForm, submitForm, trackVisitForm } from '../components/GA'
import { size } from '../constants'
import { toSendDataFormat } from '../atoms/useFormValues'
import { useErrorModalContext } from '../organisms/RegistrationModals/hooks/useErrorProvider'
import { useLocation } from '@reach/router'
import { useMutation } from '@apollo/client'
import { useSendCodeModalContext } from '../organisms/RegistrationModals/CodeSendModal/hooks/useSendCodeModalContext'
import { useSessionStorage } from '../../youtalk-storybook/src/ui'
import { useTrackConsultantStartEffect } from '../components/amplitude/trackClientServiceConsultantStart'

const Page = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 76px repeat(2, auto);
  min-height: calc(var(--vh, 1vh) * 100);
  background-color: #e5ecf1;

  @media (max-width: ${size.xs}) {
    grid-template-rows: 56px repeat(2, auto);
  }
`

const PageGrid = styled(Grid)`
  width: 587px;
  margin-top: 48px;
  margin-bottom: 330px;

  @media (max-width: ${size.lg}) {
    margin-bottom: 89px;
  }

  @media (max-width: ${size.md}) {
    margin-bottom: 103px;
  }

  @media (max-width: ${size.sm}) {
    margin-top: 24px;
    margin-bottom: 160px;
    width: 100%;
  }
`

const useFormSubmit = ({ catalogFiltersData }) => {
  const { onServerError } = useErrorModalContext()
  const [sendRegistrationRequest] = useMutation(SEND_REGISTRATION_REQUEST)
  const { modal } = useSendCodeModalContext()

  return async (values) => {
    const utm = getUTM()
    const {
      uid,
      utm_source: utmSource,
      click_id: clickId,
      utm_medium: utmMedium,
      utm_term: utmTerm
    } = utm
    pressSendForm('PressSendFormB2C', 'b2c')
    const formattedData = toSendDataFormat({
      formData: {
        ...values,
        registrationSource: RegistrationSource.Form,
        sourceId: catalogFiltersData.sourceId,
        promocodeId: values.promocodeSubField?.id,
        userRegistrationPreferences: formatCatalogData({
          ...omit(['age', 'name', 'sourceId'], catalogFiltersData),
          aboutHelp: values.aboutHelp
        })
      },
      utm,
      sourceId: catalogFiltersData?.sourceId ?? values.sourceId,
      pointOfContact: 'ks_subscription'
    })
    try {
      sendFormDataCarrotQuest({
        name: formattedData.name,
        phone: formattedData.phone,
        email: formattedData.email
      })

      const response = await sendRegistrationRequest({
        variables: {
          input: formattedData
        }
      })

      if (response.data.sendRegistrationRequest?.token) {
        submitForm('coordinator')
        modal.open({
          token: response.data.sendRegistrationRequest?.token,
          datetime: response.data.sendRegistrationRequest?.datetime,
          tel: values.tel,
          country: values.countryCode,
          promocodeId: values.promocodeSubField?.id,
          timezone: values.timezone,
          email: values.email,
          catalogFiltersData: {
            ...catalogFiltersData,
            sourceId: catalogFiltersData?.sourceId ?? values.sourceId,
            age: values.age,
            name: values.name
          }
        })
        const bitrixId = response.data.sendRegistrationRequest?.bitrixLeadId
        if (utmMedium === 'cpa') {
          utmSource === 'advertise' && sendPostBack({ bitrixId, uid })
          if (utmSource === 'network_admitad') {
            const admitad_uid = Cookies.get('admitad_uid')
            sendPostBackAdmitad(bitrixId, admitad_uid)
          }
          if (clickId && utmSource === 'network_cityads') {
            sendCityAdsPostback(bitrixId, clickId, utmTerm)
          }
          if (utmSource === 'network_advcake') {
            const advcake_track_id = Cookies.get('advcake_track_id')
            const advcake_track_url = Cookies.get('advcake_track_url')
            sendAdvCakePostback(bitrixId, advcake_track_id, advcake_track_url)
          }
        }
      }
    } catch (err) {
      onServerError()
      return { [FORM_ERROR]: 'Failed' }
    }
  }
}

const CATALOG_FILTERS_STORAGE = 'catalog_filters'

const PageBody = () => {
  const { state } = useLocation()

  const [catalogFiltersData, setCatalogFiltersData] = useSessionStorage(
    CATALOG_FILTERS_STORAGE,
    {}
  )
  useEffect(() => {
    trackVisitForm('direct')
    if (state?.filters) {
      setCatalogFiltersData({
        ...catalogFiltersData,
        ...state.filters
      })
    }
  }, [])

  useTrackConsultantStartEffect()

  const submit = useFormSubmit({ catalogFiltersData })

  return <SelectPsychologistForm onSubmit={submit} />
}

const Form = () => (
  <App>
    <SEO title="Запись к психологу онлайн | YouTalk" />
    <Page>
      <Header />
      <PageGrid>
        <SendCodeModalWithContext>
          <PageBody />
        </SendCodeModalWithContext>
      </PageGrid>
    </Page>
  </App>
)

export default Form
