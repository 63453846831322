import React from 'react'
import createDecorator from 'final-form-focus'
import styled from 'styled-components'
import { AboutHelp } from './Form/AboutHelp'
import { Age } from './Form/Age'
import { AgreeList } from './Form/AgreeList'
import { Agreement } from './Form/Agreement'
import { Button } from '../atoms/Button'
import { Email, InternationalTelephone, Name } from './Form/Input'
import { Form as FinalForm } from 'react-final-form'
import { FormButton } from './Form/FormButton'
import { Promocode, setFormStatePromocode } from './Form/Promocode'
import { PsychologistPlate } from '../molecules/PsychologistPlate'
import { SourceID } from '../atoms/mappers/wizard/enums/sourceId'
import { Timezone } from './Form/Timezone'
import { Title } from '../atoms/Title'
import { formAgeValidate } from '../atoms/FormItems/validators/age'
import { size } from '../constants'
import {
  submit,
  useInitialFormValuesAfterDOMLoad,
  useInitialFormValuesFromParams
} from '../atoms/useFormValues'

const FormGridRow = styled.div`
  display: grid;
  grid-template-columns: auto 180px;
  grid-gap: 24px;

  @media (max-width: ${size.sm}) {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`

const focusOnError = createDecorator()

const subscription = {
  errors: true,
  hasSubmitErrors: true,
  hasValidationErrors: true,
  invalid: true,
  pristine: true,
  submitting: true,
  values: true
}

const Form = styled(({ className, handleSubmit, restart, errors }) => {
  useInitialFormValuesFromParams([{ fieldName: 'promo' }])
  useInitialFormValuesAfterDOMLoad([{ fieldName: 'tel', value: '+7' }])

  return (
    <form
      noValidate
      className={className}
      onSubmit={submit({
        handleSubmit,
        restart,
        errors,
        initialValues
      })}
    >
      <Title.H4>Заявка</Title.H4>
      <FormGridRow>
        <Name label="Имя" />
        <Age label="Возраст" />
      </FormGridRow>
      <Email />
      <InternationalTelephone />
      <AboutHelp />
      <Timezone />
      <Promocode />
      <AgreeList />
      <FormButton />
      <Agreement />
    </form>
  )
})`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  box-shadow: 0px 30px 50px rgba(41, 99, 163, 0.1);
  border-radius: 16px;
  background: #fff;
  padding: 24px;

  & > ${Title.H4} {
    @media (max-width: ${size.sm}) {
      padding-bottom: 8px;
    }
  }

  & > ${Button.NewPrimary} {
    width: fit-content;

    @media (max-width: ${size.sm}) {
      width: 100%;
    }
  }

  @media (max-width: ${size.sm}) {
    padding: 16px;
    width: 100%;
  }

  ${PsychologistPlate} {
    margin-bottom: 8px;

    @media (max-width: ${size.sm}) {
      margin-bottom: 0px;
    }
  }
`

const initialValues = {
  timezone: 'GMT +3',
  sourceId: SourceID.WEB,
  countryCode: 'RU'
}

export const SelectPsychologistForm = ({ onSubmit }) => (
  <FinalForm
    decorators={[focusOnError]}
    initialValues={initialValues}
    mutators={{
      setFormStatePromocode
    }}
    onSubmit={onSubmit}
    render={({ handleSubmit, form: { restart }, errors }) => (
      <Form errors={errors} handleSubmit={handleSubmit} restart={restart} />
    )}
    subscription={subscription}
    validate={formAgeValidate}
  />
)
